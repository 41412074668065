import styled from "styled-components"

export const HeaderWrapper = styled.header`
  display: flex;
  min-height: 15vh;
  text-align: center;
  align-items: center;
  background-color: ${(props) => props.theme.color.primary};
  padding: 120px 0 60px 0;
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`
