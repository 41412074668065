import React from "react"

import { Container } from "../../global"

import { HeaderWrapper } from "./style"

const Header = ({ children }) => {
  return (
    <HeaderWrapper id="top">
      <Container>{children}</Container>
    </HeaderWrapper>
  )
}

export default Header
