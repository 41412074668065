import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import Layout from "../../components/common/layout/layout"
import SEO from "../../components/common/layout/seo"
import Navigation from "../../components/common/navigation/navigation"

import Header from "../../components/common/header"
import Footer from "../../components/common/footer"

export default function PostTemplate({ data }) {
  const post = data.markdownRemark
  console.log(post)
  return (
    <>
      <Layout>
        <SEO title={post.frontmatter.title} />
        <Navigation />
        <Header>
          <PageTitle>{post.frontmatter.title}</PageTitle>
          <small style={{ color: "white" }}>{post.frontmatter.date}</small>
        </Header>
        <ImgContainer>
          <Img fluid={post.frontmatter.thumbnail.childImageSharp.fluid} />
        </ImgContainer>
        <PageContent dangerouslySetInnerHTML={{ __html: post.html }} />
        <Footer />
      </Layout>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date
        title
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 630) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const PageTitle = styled.h1`
  color: ${({ theme }) => theme.color.white.regular};
`

const PageContent = styled.div`
  max-width: 800px;
  min-height: 600px;
  padding: 1em;
  margin: 3em auto;
  text-align: justify;
  line-height: 2;
  color: ${({ theme }) => theme.color.primary};
`

const ImgContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
`
